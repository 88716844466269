<template>
  <div>
    <a-form
      class="simiot-nested-search-form"
      :form="form"
      @submit="handleSearch"
    >
      <a-row :gutter="24">
        <a-col :xl="8" :xxl="6">
          <a-form-item label="划拨批次">
            <a-input
              v-decorator="['operation_no', {
                normalize: this.$lodash.trim
              }]"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :xl="8" :xxl="6" v-show="expand">
          <a-form-item label="项目编号">
            <a-input
              v-decorator="['item_no', {
                normalize: this.$lodash.trim
              }]"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :xl="8" :xxl="6" v-show="expand">
          <a-form-item label="订单编号">
            <a-input
              v-decorator="['order_no', {
                normalize: this.$lodash.trim
              }]"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :xl="8" :xxl="6" v-show="expand">
          <a-form-item label="划拨人">
            <a-select
              show-search
              allow-clear
              option-filter-prop="children"
              :filter-option="filterOption"
              :loading="loadingOperatorOption"
              v-decorator="['operator_id']"
            >
              <a-select-option
                v-for="operator in operators"
                :key="operator.id"
                :value="operator.id"
              >
                {{ operator.full_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :xl="8" :xxl="6" v-show="expand">
          <a-form-item label="划拨时间">
            <a-range-picker v-decorator="['created_at']" />
          </a-form-item>
        </a-col>

        <a-col :xl="8" :xxl="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
              <a-button :style="{ fontSize: '12px', lineHeight: '32px' }" @click="toggle">
                更多条件
                <a-icon :type="expand ? 'up' : 'down'" />
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import { formatRangeBeginDate, formatRangeEndDate } from '@/utils/time'
import { findManageUserOptions } from '@/api/user'
export default {
  name: 'SearchAllocationRecord',
  data() {
    return {
      form: this.$form.createForm(this, { name: 'allocation_record_search' }),
      expand: false,
      operators: [], // 划拨人
      loadingOperatorOption: false
    }
  },
  created() {
    this.fetchOperatorOptions()
  },
  methods: {
    toggle() {
      this.expand = !this.expand
    },

    // 加载划拨人
    fetchOperatorOptions() {
      this.loadingOperatorOption = true
      findManageUserOptions().then((res) => {
        if (res.code === 0) {
          this.operators = res.data
        }
        this.loadingOperatorOption = false
      })
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },

    handleSearch(e) {
      e.preventDefault()
      const fieldsValue = this.form.getFieldsValue()
      const values = {
        ...fieldsValue,
        created_at_begin: formatRangeBeginDate(fieldsValue.created_at),
        created_at_end: formatRangeEndDate(fieldsValue.created_at)
      }
      this.$emit('submit', values)
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit')
    }
  }
}
</script>
