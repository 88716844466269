<template>
  <div>
    <search-allocation-record @submit="submitSearch" />

    <div class="simiot-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="exportData">导出</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
    >
      <div slot="operation_no" slot-scope="operation_no, record">
        <router-link :to="{ name: 'allocation_record_info', params: { id: record.id }}">
          {{ operation_no }}
        </router-link>
      </div>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
  </div>
</template>

<script>
import SearchAllocationRecord from '@/views/allocation_records/Search'
import Pagination from '@/components/Pagination'
import { findAllocationRecords, exportAllocationRecords } from '@/api/allocation_record.js'
import { exportExcel } from '@/api/excel'

export default {
  name: 'AllocationRecordList',
  components: {
    SearchAllocationRecord,
    Pagination
  },
  data() {
    return {
      data: [],
      loading: true,
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '划拨批次',
          width: 100,
          dataIndex: 'operation_no',
          fixed: 'left',
          scopedSlots: { customRender: 'operation_no' }
        },
        {
          title: '订单编号',
          width: 240,
          dataIndex: 'order_no'
        },
        {
          title: '项目编号',
          width: 240,
          dataIndex: 'item_no'
        },
        {
          title: '卡号总数',
          width: 100,
          dataIndex: 'operation_count'
        },
        {
          title: '划拨成功数',
          width: 100,
          dataIndex: 'success_count'
        },
        {
          title: '划拨失败数',
          width: 100,
          dataIndex: 'failure_count'
        },
        {
          title: '划拨人',
          width: 100,
          dataIndex: 'operator'
        },
        {
          title: '划拨时间',
          width: 200,
          dataIndex: 'created_at'
        },
        {
          title: '备注',
          width: 200,
          dataIndex: 'remark',
          ellipsis: true
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findAllocationRecords(this.query).then(res => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    exportData() {
      exportAllocationRecords(Object.assign({}, this.query)).then((res) => {
        if (res.code === 0 && res.data.is_direct_download) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>

</style>
